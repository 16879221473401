export const drawings = [
	{
		"id": 1,
		"jump": "pilgimage",
		"title": "The Pilgrimage",
		"pic": "../img/drawings/pilgrimage.jpg",
		"alt": "The Pilgrimage, pen and ink drawing",
		"size": "12x16 in.",
		"medium": "Copic Marker, Micron Pen",
		"year": "2020",
		"desc": "Part of the ink spiral series.",
		"left": "image",
		"right": "reg",
		"orientation": "work"
	},
	{
		"id": 2,
		"jump": "drone",
		"title": "Drone Seeker",
		"pic": "../img/drawings/drone-seeker.jpg",
		"alt": "Drone Seeker, Pen and Ink Drawing",
		"size": "12x16 in.",
		"year": "2020",
		"desc": "Copic Market, Micron Pen. Part of the ink spiral series.",
		"left": "image",
		"right": "reg",
		"orientation": "work"
	},
	{
		"id": 3,
		"jump": "nectar",
		"title": "The Last Nectar",
		"pic": "../img/drawings/the-last-nectar.jpg",
		"alt": "The Last Nectar, Charcoal Drawing",
		"size": "16x12 in.",
		"year": "2018",
		"desc": "Charcoal. Harvesting at night.",
		"left": "imagewide",
		"right": "slim",
		"orientation": "landscape"
	},
	{
		"id": 4,
		"jump": "escape",
		"title": "The Escape",
		"pic": "../img/drawings/the-escape.jpg",
		"size": "16x12 in.",
		"medium": "Graphite, Charcoal",
		"year": "2018",
		"desc": "Running from the waves.",
		"left": "imagewide",
		"right": "slim",
		"orientation": "work"
	},
	{
		"id": 5,
		"jump": "transmission",
		"title": "Transmission / Reflection",
		"pic": "../img/drawings/transmission.jpg",
		"alt": "Transmission/Reflection, Graphite and Charcoal Drawing",
		"size": "16x12 in.",
		"year": "2018",
		"desc": "Graphite, Charcoal. Sending those signals.",
		"left": "imagewide",
		"right": "slim",
		"orientation": "work"
	},
	{
		"id": 6,
		"jump": "flooded",
		"title": "Flooded",
		"pic": "../img/drawings/flooded.jpg",
		"alt": "Flooded, Graphite and Charcoal Drawing",
		"size": "12x16 in.",
		"year": "2018",
		"desc": "Graphite, Charcoal. We'll go back the other way.",
		"left": "image",
		"right": "reg",
		"orientation": "work"
	},
]