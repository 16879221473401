export const digital = [
	{
		"id": 1,
		"jump": "kollapse",
		"title": "Kollapse",
		"pic": "../img/digital/kollapse.jpg",
		"alt": "Collapse - Digital Artwork",
		"year": "2020",
		"left": "image",
		"right": "reg",
	},
	{
		"id": 2,
		"jump": "melt",
		"title": "Melt Rock",
		"pic": "../img/digital/melt-rock.jpg",
		"size": "6000x6000 px.",
		"year": "2020",
		"alt": "Melt Roc, Digital Artwork",
		"left": "imagewide",
		"right": "slim",
	},
	{
		"id": 3,
		"jump": "geo",
		"title": "Geometrigram",
		"pic": "../img/digital/geometrigram.jpg",
		"size": "4800x6000 px.",
		"year": "2017",
		"alt": "Geometrigram, Digital Artwork",
		"left": "image",
		"right": "reg",
	},
	{
		"id": 4,
		"jump": "modernruins",
		"title": "Modern Ruins",
		"pic": "../img/digital/modern-ruins.jpg",
		"size": "4800x6000 px.",
		"year": "2019",
		"alt": "Modern Ruins, Digital Artwork",
		"left": "image shadow",
		"right": "reg"
	},
	{
		"id": 5,
		"jump": "owl",
		"title": "Owlluminati",
		"pic": "../img/digital/owl-eyes.jpg",
		"size": "5400x5400 px.",
		"year": "2020",
		"alt": "Owlluminati, Digital Artwork",
		"left": "image",
		"right": "reg"
	},
	{
		"id": 6,
		"jump": "spectral1",
		"title": "Spectral Rhythms I",
		"pic": "../img/digital/spectral-rhythms1.jpg",
		"size": "6000x6000 px.",
		"year": "2020",
		"alt": "Spectral Rhythms 1, Digital Artwork",
		"left": "image",
		"right": "reg"
	},
	{
		"id": 6,
		"jump": "spectral2",
		"title": "Spectral Rhythms II",
		"pic": "../img/digital/spectral-rhythms2.jpg",
		"size": "6000x6000 px.",
		"year": "2021",
		"alt": "Spectral Rhythms 2, Digital Artwork ",
		"left": "image",
		"right": "reg"
	},
]