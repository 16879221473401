export const list = [
	{
		"id": 1,
		"title": "Demiurge",
		"thumb": "../img/thumb/demiurge.jpg",
		"desc": "Digital Art Series",
		"url": "../works/demiurge",
	},
	{
		"id": 2,
		"title": "Solid Smoke",
		"thumb": "../img/thumb/solidsmoke.jpg",
		"desc": "Digital Art Series",
		"url": "../works/solidsmoke",
	},
	{
		"id": 3,
		"title": "Forest Magic",
		"thumb": "../img/thumb/forestmagic.jpg",
		"desc": "Digital Art Series",
		"url": "../works/forest",
	},
	{
		"id": 4,
		"title": "Washed",
		"thumb": "../img/thumb/washed.jpg",
		"desc": "Digital Art Series",
		"url": "../works/washed",
	},
	{
		"id": 5,
		"title": "Mountain Waves",
		"thumb": "../img/thumb/waves.jpg",
		"desc": "Photo Manipulation Series",
		"url": "../works/mountain",
	},
	{
		"id": 6,
		"title": "Digital Archive",
		"thumb": "../img/thumb/digital.jpg",
		"desc": "Archive of Digital Works",
		"url": "../works/digital",
	},
	{
		"id": 7,
		"title": "Drawings",
		"thumb": "../img/thumb/drawings.jpg",
		"desc": "Archive of Drawings",
		"url": "../works/drawings",
	},
	{
		"id": 8,
		"title": "Paintings",
		"thumb": "../img/thumb/paintings.jpg",
		"desc": "Archive of Paintings",
		"url": "../works/paintings",
	},
	{
		"id": 9,
		"title": "Chris Lippincott",
		"thumb": "../img/thumb/lippincott.jpg",
		"desc": "Digital Album Art",
		"url": "../works/lippincott",
	},
	{
		"id": 10,
		"title": "Wide Waters",
		"thumb": "../img/thumb/widewaters.jpg",
		"desc": "Branding & Album Art",
		"url": "../works/widewaters",
	},
	{
		"id": 11,
		"title": "Brooksofyork",
		"thumb": "../img/thumb/brooksofyork.jpg",
		"desc": "Digital Album Art",
		"url": "../works/brooksofyork",
	},
	{
		"id": 12,
		"title": "Front Porch Brewing",
		"thumb": "../img/thumb/frontporch.jpg",
		"desc": "Branding & Packaging Art",
		"url": "../works/frontporch",
	},
	
	/* {
		"id": 4,
		"title": "Dream Tracer",
		"thumb": "../img/thumb/dreamcards.jpg",
		"desc": "Deck of Digital Cards",
		"url": "../works/dreamcards",
	}, */
]